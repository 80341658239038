<template>
  <div class="w-full text-center" v-if="isLoading">
    <asom-icon icon="spinner" class="animate-spin" />
  </div>
  <div v-else class="space-y-10">
    <asom-card title="Filter">
      <div class="w-1/2">
        <asom-form-field label="Date">
          <asom-input-date-range v-model="filters.dateRange" />
        </asom-form-field>
      </div>
    </asom-card>
    <asom-client-table
      :columns="[
        'no',
        'officer',
        'planDate',
        'planStart',
        'planEnd',
        'hours',
        'dwsText',
      ]"
      :data="availabilityRequests"
      :sortableColumns="[
        'officer',
        'planDate',
        'planStart',
        'planEnd',
        'hours',
        'dwsText',
      ]"
    >
      <template v-slot:header_planDate>Plan Date</template>
      <template v-slot:header_planStart>Plan Start</template>
      <template v-slot:header_planEnd>Plan End</template>
      <template v-slot:header_dwsText>dws</template>
      <template v-slot:no="scopedSlots">
        {{ scopedSlots.rowIndex + 1 }}
      </template>
    </asom-client-table>
  </div>
</template>

<script>
import get from "lodash.get";
import { getAvailabilityChanges } from "@/services/manpower.service";

export default {
  name: "Availabilty",
  data() {
    return {
      filters: {
        dateRange: null,
      },
      availabilityRequests: [],
      skip: 0,
      isLoading: false,
      error: null,
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {
      this.isLoading = true;
      const resp = await getAvailabilityChanges({ skip: this.skip });
      if (resp.success) {
        this.availabilityRequests = get(resp, "payload.list", []);
        this.error = null;
        this.isLoading = false;
      } else {
        this.error = resp.payload;
        this.isLoading = false;
        this.$scrollTop();
        return;
      }
    },
  },
};
</script>

<style></style>
